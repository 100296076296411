(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CardEditStatusLoanController', CardEditStatusLoanController);

    CardEditStatusLoanController.$inject = ['$scope', '$state', 'Card', 'formHelper', 'entity', '$stateParams', '$filter', 'AlertService', '$translate', 'CardStatus'];

    function CardEditStatusLoanController ($scope, $state, Card, formHelper, entity, $stateParams, $filter, AlertService, $translate, CardStatus) {
        var vm = this;
        vm.kendo = kendo;
        console.log("CardEditStatusLoanController");
        
        if($stateParams.token != null) {
	        vm.cardholder = entity.cardholder[0];
	        vm.cards = vm.cardholder.card;
	        
	        vm.currentCard = $filter('filter')(vm.cards, {token: $stateParams.token}, false)[0];
	        vm.statusStyle = CardStatus.getStyle(vm.currentCard.stat_code);
        }

        vm.statusDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "00",
    			value: $translate.instant('modules.cardStatus.00')
    		},{
    			key: "05",
    			value: $translate.instant('modules.cardStatus.05')
    		},{
    			key: "04",
    			value: $translate.instant('modules.cardStatus.04')
    		}]
    	});
        
        
        
        vm.save = function(){

        	vm.formCard = angular.copy(vm.currentCard);
	    	vm.cmd = angular.copy(vm.formCard.cmd);
	    	delete vm.formCard.cmd;
	    	vm.bal = angular.copy(vm.formCard.bal);
	    	delete vm.formCard.bal;
        	
	    	vm.formCard.sendBySms = vm.sendBySms;
	    	vm.formCard.reason = vm.reason;
	    	
        	Card.status(vm.formCard,
        			function(result){
		        		if(result) {
		        			AlertService.success("Status changed successfully");
		        			vm.formCard = result;
		        			delete vm.formCard.sendBySms;
		        			delete vm.formCard.reference;
		        			delete vm.formCard.reason;
		        			vm.currentCard = angular.copy(result);
		        			vm.currentCard.cmd = vm.cmd;
		        			vm.currentCard.bal = vm.bal;
		        			$state.go('modules.loans.current.card.list.view', {id: $stateParams.id, token: $stateParams.token}, {reload: false});
		        		}
	        		},
	        		function(error){
	        			console.log(error);
	        		}
        	).$promise;
        	
        };
        
        vm.cancel = function(){
        	$state.go('modules.loans.current.card.list.view', {id: $stateParams.id, token: $stateParams.token});
        };
        
    }
    
})();

