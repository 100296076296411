(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CardViewLoanController', CardViewLoanController);

    CardViewLoanController.$inject = ['$scope', '$state', 'Card', 'formHelper', 'entity', '$stateParams', '$filter', 'defaultPageSize', 'menuWithHeader', 'events', 'CardStatus'];

    function CardViewLoanController ($scope, $state, Card, formHelper, entity, $stateParams, $filter, defaultPageSize, menuWithHeader, events, CardStatus) {
        var vm = this;
        vm.kendo = kendo;
        console.log("Card View controller");
        
        console.log($stateParams.token);
        if($stateParams.token != null) {
        vm.cardholder = entity.cardholder[0];
        vm.cards = vm.cardholder.card;
        
        vm.currentCard = $filter('filter')(vm.cards, {token: $stateParams.token}, false)[0];
        vm.statusStyle = CardStatus.getStyle(vm.currentCard.stat_code);
        if(typeof vm.currentCard.bal != 'undefined' && vm.currentCard.bal.length > 0) {
        	vm.cardBalDataSource = new kendo.data.DataSource({
        		schema: {
  			      model: {
//  			        id: "token",
  			        fields: {
//  			          "token": { type: "string"},
  			          "bal_dtime": { type: "date" },
  			        }
  			      }
        		},
    			data: vm.currentCard.bal,
    			pageSize: defaultPageSize,
    			sort: {
    				field: "bal_dtime",
    				dir: "desc"
    			}
            });
        	
        	vm.cardBalOptions = {
            		scrollable: true,
                    sortable: true,
                    filterable: {
                        extra: false,
                        operators: {
                            string: {
                                startswith: "Starts with",
                                eq: "Is equal to",
                                neq: "Is not equal to"
                            }
                        }
                    },
                    pageable: {
                    	messages: {
                    	      empty: "No data"
                    	}
                    },
                    columns: [{
	                    	field: "bal_dtime",
	                    	headerTemplate: "<span translate='modules.loan.table.bal_date'>",
                            format      : "{0:yyyy-MM-dd hh:mm:ss}",
                            parseFormats: ["yyyy-MM-dd'T'HH:mm:ss.zzz"],
		                    width: "15ch",
		                    filterable: {
		                    	ui: "datepicker"
		                    }
	                    },{
                            field: "amt_avl",
                            headerTemplate: "<span translate='modules.loan.table.amt_avl'>",
//                            minScreenWidth: 992,
                            width: "15ch",
                            format: "{0:n2}",
                            attributes: {class:"text-right"},
                            filterable: false
                        },{
                            field: "blk_amt",
                            headerTemplate: "<span translate='modules.loan.table.blk_amt'>",
//                            minScreenWidth: 992,
                            width: "15ch",
                            format: "{0:n2}",
                            attributes: {class:"text-right"},
                            filterable: false
                        },{
                            field: "cred_limit",
                            headerTemplate: "<span translate='modules.loan.table.cred_limit'>",
//                            minScreenWidth: 992,
                            width: "15ch",
                            format: "{0:n2}",
                            attributes: {class:"text-right"},
                            filterable: false
                        },{
                            field: "fin_amt",
                            headerTemplate: "<span translate='modules.loan.table.fin_amt'>",
//                            minScreenWidth: 992,
                            width: "15ch",
                            format: "{0:n2}",
                            attributes: {class:"text-right"},
                            filterable: false
                        }]
            };
        	
        }
        
        if(typeof vm.currentCard.cmd != 'undefined' && vm.currentCard.cmd.length > 0) {
        	vm.cardCmdDataSource = new kendo.data.DataSource({
        		schema: {
    			      model: {
//    			        id: "token",
    			        fields: {
//    			          "token": { type: "string"},
    			          "cmd_dtime": { type: "date" },
    			        }
    			      }
          		},
    			data: vm.currentCard.cmd,
    			pageSize: defaultPageSize,
    			sort: {
    				field: "cmd_dtime",
    				dir: "desc"
    			}
            });
        	
        	vm.cardCmdOptions = {
            		scrollable: true,
                    sortable: true,
                    filterable: {
                        extra: false,
                        operators: {
                            string: {
                                startswith: "Starts with",
                                eq: "Is equal to",
                                neq: "Is not equal to"
                            }
                        }
                    },
                    pageable: {
                    	messages: {
                    	      empty: "No data"
                    	}
                    },
                    columns: [{
	                    	field: "cmd_dtime",
	                    	headerTemplate: "<span translate='modules.loan.table.cmd_date'>",
                            format      : "{0:yyyy-MM-dd hh:mm:ss}",
                            parseFormats: ["yyyy-MM-dd'T'HH:mm:ss.zzz"],
		                    width: "15ch",
		                    filterable: {
		                    	ui: "datepicker"
		                    }
	                    },{
                            field: "amount",
                            headerTemplate: "<span translate='modules.loan.table.amount'>",
//                            minScreenWidth: 992,
                            width: "15ch",
                            format: "{0:n2}",
                            attributes: {class:"text-right"},
                            filterable: false
                        },{
                            field: "rec_type",
                            headerTemplate: "<span translate='modules.loan.table.rec_type'>",
//                            minScreenWidth: 992,
                            width: "15ch"
                        },{
                            field: "sts",
                            headerTemplate: "<span translate='modules.loan.table.sts'>",
//                            minScreenWidth: 992,
                            width: "15ch",
                        }]
            };
        	
           }
        }
        
        vm.eventsDataSource = new kendo.data.DataSource({
			data: events,
			aggregate: [
            	{ field: "tolken", aggregate: "count"}
            ],
            pageSize: defaultPageSize,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            schema: {
                model: {
                    fields: {
                        tolken: { type: "string" },
                        statDesc: { type: "string" },
                        eventDate: { type: "date" },
                        activationDate: { type: "date" },
                        eventType: { type: "string" },
                        id: { type: "string" }
                    }
                }
            },
            sort: { field: "id", dir: "desc"}
        });
        
        vm.eventsOptions = {
                filterable: false,
                sortable: true,
                pageable: {
                	messages: {
                	      empty: "No data"
                	}
                },
                columns: [ 
                          {
                              field:"id",
                              headerTemplate: "<span translate='modules.loan.table.no'>",
                              attributes: { "class": "text-center" },
                              width: "7ch"
                          },
                          {
                              field: "tolken",
                              headerTemplate: "<span translate='modules.loan.table.cardNo'>",
                              sortable: false,
                              aggregates: ["count"], 
                              footerTemplate: "<span translate='modules.loan.table.total'></span>: #=count#",
                              footerAttributes: { "class": "text-center" },
                              attributes: { "class": "text-center" },
                              width: "20ch",
                              minScreenWidth: 992
                          },
                          {
                              field: "eventDate",
                              headerTemplate: "<span translate='modules.loan.table.eventDate'>",
                              format      : "{0:yyyy-MM-dd hh:mm:ss}",
                              parseFormats: ["yyyy-MM-dd'T'HH:mm:ss.zzz"],
                              attributes: { "class": "text-center" },
                              width: "20ch"
                          },
                          {
                              field: "statDesc",
                              headerTemplate: "<span translate='modules.loan.table.statusCard'>",
                              attributes: { "class": "text-center" },
                              width: "10ch"
                          },
                          {
                              field: "eventType",
                              headerTemplate: "<span translate='modules.loan.table.eventType'>",
                              attributes: { "class": "text-center" },
                              width: "20ch"
                          }, 
                          {
                              field: "activationDate",
                              headerTemplate: "<span translate='modules.loan.table.activationDate'>",
                              format: "{0:yyyy-MM-dd hh:mm:ss}",
                              parseFormats: ["yyyy-MM-dd'T'HH:mm:ss.zzz"],
                              attributes: { "class": "text-center" },
                              width: "20ch",
                              minScreenWidth: 1200
                          }
                      ],
        };
        
        
        vm.changeStatus = function(){
        	$state.go('modules.loans.current.card.list.edit-status', {token: $stateParams.token});
        };
        
        vm.changePin = function(){
        	$state.go('modules.loans.current.card.list.edit-pin', {token: $stateParams.token});
        };
        
        
        vm.isEdit = function(){
        	var isEdit = false;
            var right = menuWithHeader[0].right;
            
            var currentRight = $filter('filter')(right, {state: $state.current.name}, false)[0];
            if(currentRight != null && currentRight.isEdit)
            	return true;
            
            return isEdit;

        }
    }
    
})();
