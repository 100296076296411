(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CardEditPinLoanController', CardEditPinLoanController);

    CardEditPinLoanController.$inject = ['$scope', '$state', 'Card', 'formHelper', 'entity', '$stateParams', '$filter', 'AlertService', 'CardStatus'];

    function CardEditPinLoanController ($scope, $state, Card, formHelper, entity, $stateParams, $filter, AlertService, CardStatus) {
        var vm = this;
        vm.kendo = kendo;
        console.log("CardEditPinLoanController");
        
        if($stateParams.id != null) {
	        vm.cardholder = entity.cardholder[0];
	        vm.cards = vm.cardholder.card;
	        
	        vm.currentCard = $filter('filter')(vm.cards, {token: $stateParams.token}, false)[0];
	        vm.statusStyle = CardStatus.getStyle(vm.currentCard.stat_code);
        }
        
        vm.save = function(){
        	vm.formCard = angular.copy(vm.currentCard);
	    	vm.cmd = angular.copy(vm.formCard.cmd);
	    	delete vm.formCard.cmd;
	    	vm.bal = angular.copy(vm.formCard.bal);
	    	delete vm.formCard.bal;
	    	vm.formCard.sendBySms = vm.sendBySms;
	    	vm.formCard.newPin = vm.newPin;
	    	
	    	vm.formCard.reference = vm.cardholder.reference;
        	Card.pin(vm.formCard,
        			function(result){
		        		if(result) {
		        			AlertService.success("PIN changed successfully, new PIN is '"+ vm.formCard.newPin +"'");
		        			vm.formCard = result;
		        			delete vm.formCard.sendBySms;
		        			delete vm.formCard.reference;
		        			delete vm.formCard.newPin;
		        			vm.currentCard = angular.copy(result);
		        			vm.currentCard.cmd = vm.cmd;
		        			vm.currentCard.bal = vm.bal;
		        			$state.go('modules.loans.current.card.list.view', {id: $stateParams.id, token: $stateParams.token});
		        		}
	        		},
	        		function(error){
	        			console.log(error);
	        		}
        	).$promise;
        	
        };
        
        vm.cancel = function(){
        	$state.go('modules.loans.current.card.list.view', {id: $stateParams.id, token: $stateParams.token});
        };
        
    }
    
})();
