(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CardHolderLoanController', CardHolderLoanController);

    CardHolderLoanController.$inject = ['$scope', '$state', 'Card', 'formHelper', 'entity', '$translate'];

    function CardHolderLoanController ($scope, $state, Card, formHelper, entity, $translate) {
        var vm = this;
        vm.kendo = kendo;
        console.log("Card Holder Loan Controller started");
        console.log(entity);
        
        if($state.current.name == 'modules.loans.current.card')
        	vm.readonly=true;
        else
        	vm.readonly=false;
        
        vm.dateFormat = kendo.culture().calendar.patterns.d;
    	vm.patternNationality = "[a-zA-Z]{2}|[a-zA-Z]{3}";
        
        vm.holder = entity.cardholder[0];
        	
        vm.cancel = function(){
        	console.log('cancel');
        	$state.go('modules.loans.current.card');
        };
        	
        vm.save	= function(){
        	console.log('save');
        	$state.go('modules.loans.current.card');
        };
        
        vm.edit	= function(){
        	console.log('edit');
        	$state.go('modules.loans.current.card.edit');
        };
        
        vm.sexDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "F",
    			value: $translate.instant("modules.loan.female")
    		},{
    			key: "M",
    			value: $translate.instant("modules.loan.male")
    		}]
    	});
        
    }
    
})();
