(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
	        .state('modules.loans', {
				url : '/loans',
				parent : 'modules',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'content@' : {
						templateUrl : 'app/modules/loan/loans.template.html',
						controller : 'LoansController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.loans.new', {
				url : '/new/fromAppl/{applId}',
				parent : 'modules.loans',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'content@' : {
						templateUrl : 'app/modules/loan/new.loan.template.html',
						controller : 'NewLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/application');
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                application: ['$stateParams', 'Application', function($stateParams, Application) {
	                	if($stateParams.applId == null ) return null;
	                    return Application.get({id : $stateParams.applId}).$promise;
	                }],
	                detailMode: function(){
	                	return {
	                		isNew: true
	                	};
	                },
	                previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.applications',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }]
	                
				}
			})
        	.state('modules.loans.current', {
				url : '/{loan_no}',
				parent : 'modules.loans',
				abstract: true,
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'content@' : {
						templateUrl : 'app/modules/loan/loan.template.html',
						controller : 'LoanMenuController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                menuWithHeader: ['$stateParams', 'Loan', function($stateParams, Loan) {
	                    return Loan.get({loan_no : $stateParams.loan_no}).$promise;
	                }]
				}
			})
			.state('modules.loans.current.delete', {
				url : '/delete',
				parent : 'modules.loans.current',
				params: {applId: null},
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/modules/loan/delete.loan.template.html',
	                    controller: 'DeleteLoanController',
	                    controllerAs: 'vm',
	                    size: 'md'
	                }).result.then(function() {
//	                    $state.go('modules.loans', null, { reload: true });
	                    $state.go('modules.loans.new', {applId: $state.params.applId});
	                }, function() {
	                    $state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no});
	                });
	            }]
	        })
	       	.state('modules.loans.current.condition', {
				url : '/',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-body' : {
						templateUrl : 'app/modules/loan/condition/conditions.loan.template.html',
						controller : 'ConditionsLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.loans.current.payments', {
				url : '/payments',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-body' : {
						templateUrl : 'app/modules/loan/payments/payment.list.loan.template.html',
						controller : 'PaymentListLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                payments: ['$stateParams', 'Loan', function($stateParams, Loan) {
	                    return Loan.getPayments({loan_no : $stateParams.loan_no}).$promise;
	                }]
				}
			})
			.state('modules.loans.current.schedules', {
				url : '/schedules',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-body' : {
						templateUrl : 'app/modules/loan/schedules/schedule.list.loan.template.html',
						controller : 'ScheduleListLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                schedules: ['$stateParams', 'Loan', function($stateParams, Loan) {
	                    return Loan.getSchedules({loan_no : $stateParams.loan_no}).$promise;
	                }]
				}
			})
			.state('modules.loans.current.schedules.view', {
				url : '/:schId',
				parent : 'modules.loans.current.schedules',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'loan-header@modules.loans.current' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header@modules.loans.current' : {
						templateUrl : 'app/modules/loan/schedules/schedule.list.loan.template.html',
						controller : 'ScheduleListLoanController',
						controllerAs : 'vm'
					},
					'loan-body@modules.loans.current' : {
						templateUrl : 'app/modules/loan/schedules/schedule.detail.loan.template.html',
						controller : 'ScheduleDetailLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.loans.current.operations', {
				url : '/operations',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-body' : {
						templateUrl : 'app/modules/loan/operations/operation.list.loan.template.html',
						controller : 'OperationListLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                operations: ['$stateParams', 'Loan', function($stateParams, Loan) {
	                    return Loan.getOperations({loan_no : $stateParams.loan_no}).$promise;
	                }]
				}
			})
			.state('modules.loans.current.interests', {
				url : '/interests',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header' : {
						templateUrl : 'app/modules/loan/interests/interest.list.loan.template.html',
						controller : 'InterestListLoanController',
						controllerAs : 'vm'
					},
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                interests: ['$stateParams', 'Loan', function($stateParams, Loan) {
	                    return Loan.getInterests({loan_no : $stateParams.loan_no}).$promise;
	                }]
				}
			})
			.state('modules.loans.current.interests.view', {
				url : '/:rateId',
				parent : 'modules.loans.current.interests',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'loan-header@modules.loans.current' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header@modules.loans.current' : {
						templateUrl : 'app/modules/loan/interests/interest.list.loan.template.html',
						controller : 'InterestListLoanController',
						controllerAs : 'vm'
					},
					'loan-body@modules.loans.current' : {
						templateUrl : 'app/modules/loan/interests/interest.detail.loan.template.html',
						controller : 'InterestDetailLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.loans',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: false,
	                		create: false
	                	};
	                }
				}
			})
			.state('modules.loans.current.interests.edit', {
				url : '/:rateId/edit',
				parent : 'modules.loans.current.interests',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'loan-header@modules.loans.current' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header@modules.loans.current' : {
						templateUrl : 'app/modules/loan/interests/interest.list.loan.template.html',
						controller : 'InterestListLoanController',
						controllerAs : 'vm'
					},
					'loan-body@modules.loans.current' : {
						templateUrl : 'app/modules/loan/interests/interest.detail.loan.template.html',
						controller : 'InterestDetailLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.applications',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: true,
	                		create: false
	                	};
	                }
				}
			})
			.state('modules.loans.current.interests.create', {
				url : '/create/',
				parent : 'modules.loans.current.interests',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'loan-header@modules.loans.current' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header@modules.loans.current' : {
						templateUrl : 'app/modules/loan/interests/interest.list.loan.template.html',
						controller : 'InterestListLoanController',
						controllerAs : 'vm'
					},
					'loan-body@modules.loans.current' : {
						templateUrl : 'app/modules/loan/interests/interest.detail.loan.template.html',
						controller : 'InterestDetailLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.applications',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: true,
	                		create: true
	                	};
	                }
				}
			})
			.state('modules.loans.current.history', {
				url : '/history',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-body' : {
						templateUrl : 'app/modules/loan/history/history.loan.template.html',
						controller : 'HistoryLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('modules/history');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
	        .state('modules.loans.current.order-card', {
					url : '/order-card',
					parent : 'modules.loans.current',
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body' : {
							templateUrl : 'app/modules/loan/card/card-holder.registration.loan.template.html',
							controller : 'CardHolderRegistrationLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/loan');
		                	$translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                defaultValue : ['$stateParams', 'Card', function($stateParams, Card) {
							return Card.getDefault({loan_no : $stateParams.loan_no}).$promise;
						}]
					}
				})
				.state('modules.loans.current.card', {
					url : '/cards',
					parent : 'modules.loans.current',
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body' : {
							templateUrl : 'app/modules/loan/card/card-holder.view.loan.template.html',
							controller : 'CardHolderLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                	$translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                entity: ['$stateParams', 'Card', function($stateParams, Card) {
		                    return Card.get({loan_no : $stateParams.loan_no}).$promise;
		                }]
		            }
				})
				.state('modules.loans.current.card.edit', {
					url : '/edit',
					parent : 'modules.loans.current.card',
					views : {
						'loan-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card-holder.edit.loan.template.html',
							controller : 'CardHolderLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }]
		            }
				})
				.state('modules.loans.current.card.list', {
					url : '/list',
					parent : 'modules.loans.current.card',
					views : {
						'loan-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans' : function(){
							return "";
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }]
		            }
				
				})
				.state('modules.loans.current.cards.current.list.add-card', {
					url : '/add-card',
					parent : 'modules.loans.current.card.list',
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
						},
						'loan-sub-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card.feaure.loan.template.html'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }]
		            }
				
				})
				.state('modules.loans.current.card.list.view', {
					url : '/{token}/view',
					parent : 'modules.loans.current.card.list',
					views : {
						'loan-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card.view.loan.template.html',
							controller : 'CardViewLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                events: ['$stateParams', 'Card', function($stateParams, Card) {
		                    return Card.getEvents({loan_no : $stateParams.loan_no, token : $stateParams.token}).$promise;
		                }]
		            }
				
				})
				.state('modules.loans.current.card.list.edit-status', {
					url : '/{token}/edit-status',
					parent : 'modules.loans.current.card.list',
					views : {
						'loan-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card.edit.status.loan.template.html',
							controller : 'CardEditStatusLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }]
		            }
				
				})
				.state('modules.loans.current.card.list.edit-pin', {
					url : '/{token}/edit-pin',
					parent : 'modules.loans.current.card.list',
					views : {
						'loan-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card.edit.pin.loan.template.html',
							controller : 'CardEditPinLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }]
		            }
				
				})
	        ;
        
        
    }
})();
