(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CardListLoanController', CardListLoanController);

    CardListLoanController.$inject = ['$scope', '$state', 'Card', 'formHelper', 'entity', '$translate'];

    function CardListLoanController ($scope, $state, Card, formHelper, entity, $translate) {
        var vm = this;
        vm.kendo = kendo;
        console.log("Card List controller");
        vm.cardholder = entity.cardholder[0];
        vm.cards = vm.cardholder.card;
        
        
        vm.cardsDataSource = new kendo.data.DataSource({
        	schema: {
			      model: {
			        fields: {
			          "stat_code": { type: "string" },
			          "status": { type: "string" },
			        }
			      },
			      parse: function(d){
	                	$.each(d, function (idx, elem) {
	                        elem.status = $translate.instant('modules.cardStatus.' + elem.stat_code);
	                    });
	                	return d;
	              }
      		},
			data: vm.cards
        });
        
        vm.cardsOptions = {
        		scrollable: true,
                sortable: true,                
                filterable: false,
                toolbar: [
                          { 
		                    template: "<span style='line-height:22px;' translate='modules.loan.titles.cardsList'></span>"
		                    		 + "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs' ui-sref='modules.loan.current.card.list.add-card'>"
		                    		 + "<span class='glyphicon glyphicon-plus'></span>"
		                             + "&nbsp;<span translate='modules.loan.addCard'>"
		                             + "</span></button>"
                          }],
                columns: [{
	                    field: "token",
	                    headerTemplate: "<span translate='modules.loan.table.token'>",
                    },{
	                  field: "status",
	                  headerTemplate: "<span translate='modules.loan.table.status'>",
	                  minScreenWidth: 992,
	                  width: "16ch"
	              	},{
                        field: "assigned_limit",
                        headerTemplate: "<span translate='modules.loan.table.assigned_limit'>",
                        width: "15ch",
                        format: "{0:n2}",
                        attributes: {class:"text-right"},
                        filterable: false
                    },{
                    	headerTemplate: "<span translate='modules.loan.actions'>",
                    	template: "<button kendo-tooltip k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-default k-button-icontext' ui-sref='modules.loans.current.card.list.view({token: #:token#})'>"
                                + "<span class='glyphicon glyphicon glyphicon glyphicon-eye-open'></span>"
                                + "</button>"
                                ,
                        title: "&nbsp;", 
                        width: "10ch" 
                    }]
        };
      
       
    }
    
})();
